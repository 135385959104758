import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';
import { sendGroupSavedEvent } from './helpers/sendGroupEvent';

import GroupForm from './Form';

import { __ } from '../../i18n';
import * as utils from '../../utils';

@inject('store')
@graphql(
  gql`
    query GroupEditQuery($id: ID!) {
      node(id: $id) @connection(key: "Group", filter: ["id"]) {
        ... on Group {
          id: dbId
          name
          internal
          seeAll
          type
          entities {
            totalCount
          }
          markers(limit: 25) {
            nodes {
              id
              name
            }
          }
        }
      }
    }
  `,
  {
    options: (ownProps) => ({
      variables: {
        id: ownProps.params.group_id,
      },
    }),
  },
)
@graphql(
  gql`
    mutation updateGroup($updateGroupMutation: UpdateGroupInput!) {
      updateGroup(input: $updateGroupMutation) {
        clientMutationId
      }
    }
  `,
  {
    options: {
      refetchQueries: [
        'OrganizationGroupsQuery',
        'RoleFormGroupsQuery',
        'GroupEditQuery',
      ],
    },
  },
)
@observer
export default class GroupEdit extends Controller {
  request = ({ name, internal, seeAll, type, markerIds }) => {
    const { store, params, router, mutate } = this.props;

    let input = {
      id: params.group_id,
      name,
      internal,
      seeAll,
      type,
    };

    if (markerIds.length > 0) {
      input.markerIds = markerIds;
    }

    return mutate({
      variables: {
        updateGroupMutation: {
          ...input,
        },
      },
    })
      .then(() => {
        sendGroupSavedEvent({
          buttonName: 'salvar_grupo',
          groupName: name,
          markerIds,
        });
        store.snackbar = {
          active: true,
          message: __('%s was saved', name),
          success: true,
        };
        router.push(`/organizations/${store.currentOrganization.id}/groups/`);
      })
      .catch((err) => {
        this.setState({
          errors: { name: utils.handleError(err.graphQLErrors[0]) },
        });
      });
  };

  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { name, internal, seeAll, type } = data.node;

    const markerIds = data.node.markers.nodes.map((marker) => marker.id);

    return (
      <Controller
        id="GroupEdit"
        modal
        edit
        title={__('Edit group')}
        form={GroupForm}
        values={{ name, internal, seeAll, type, markerIds }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
